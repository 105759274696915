import React from "react";
import ContactButton from "./ContactButton";
import Container from "./Container";
import heroImage from "./../image/woman.png";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate();

  const handleAboutUsClick = () => {
    navigate("/about-us");
  };

  return (
    <Container>
      <div className="flex flex-col md:flex-row items-center py-16 justify-center bg-white">
        <div
          className="w-full md:w-3/5 h-auto"
          data-aos="fade-right"
          data-aos-mirror="true"
          data-aos-once="false"
        >
          <img
            src={heroImage}
            alt="Pastor"
            className="rounded-lg h-auto object-cover"
          />
        </div>

        <div className="mt-8 md:mt-0 md:ml-16 w-full text-left md:text-left">
          <h1
            data-aos="fade-left"
            className="text-2xl sm:text-3xl md:text-5xl font-bold text-gray-800 leading-tight"
          >
            THIS IS <br />
            <span className="text-blue-800">
              RCCG HOUSE OF <br /> MERCY HIGHWYCOMB
            </span>
          </h1>
          <p
            data-aos="fade-left"
            data-aos-delay="100"
            className="mt-2 sm:mt-4 text-base sm:text-lg md:text-xl text-gray-600 leading-relaxed"
          >
            Our passion is to possess kingdoms and raise influential godly
            leaders, generals <br /> in faith and kingdom power. To create an
            atmosphere where mercy turns as <br /> many people we come in
            contact with into a blessing for their generation.
          </p>
          <div
            data-aos="fade-left"
            data-aos-delay="200"
            className="mt-4 sm:mt-6 md:mt-8"
          >
            <ContactButton
              stylex="px-16 py-3 text-md"
              label="About Us"
              onClick={handleAboutUsClick}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HeroSection;
