import React, { useState } from "react";



const SeniorPastor = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="container mx-auto px-6 py-10 flex flex-col items-center">
      <h2
        data-aos="fade-down"
        className="text-xl sm:text-2xl md:text-5xl font-bold text-center text-indigo-700 mt-9 sm:mb-8"
      >
        Board of Trustees
      </h2>
      <div className="flex flex-col md:flex-row mt-10 justify-center md:space-x-10 w-full">
        <div className="w-full md:w-1/4 flex justify-center">
          <img
            data-aos="fade-right"
            src={require("./../image/pastor-man.png")}
            alt="Senior Pastor"
            className="w-4/5 object-cover rounded-lg md:w-[100%] md:h-[25rem]"
          />
        </div>
        <div className="w-full md:w-1/2 mt-10 md:mt-0">
          <p
            data-aos="fade-left"
            className="text-m sm:text-l md:text-3xl font-bold text-black mb-9 sm:mb-8"
          >
            Meet our senior pastor
          </p>
          <p
            data-aos="fade-left"
            data-aos-delay="100"
            className="text-base md:text-lg text-gray-700 leading-loose sm:leading-relaxed"
          >
            Engineer Olusegun Stephen is the Senior Pastor of RCCG House of
            Mercy Aylesbury. He has served continuously as a Pastor in RCCG for
            37 years. He was the Pioneer National Secretary of RCF Senior Friend
            Association, Nigeria. Olusegun was ordained as the full pastor of
            RCCG in 1998. An enthusiastic Evangelist, who desired to develop
            end-time leaders and a Bible teacher who loved to partner with the
            Holy Spirit, he has encouraged many start-up businesses. As far back
            as 2006, he planted over 25 churches in Nigeria and now over 10
            churches in the UK and Sweden in Europe. He is a COREN registered
            engineer and earned his master's degree in health and safety at
            Middlesex University in London, UK
          </p>

          <div
            className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
              showMore ? "max-h-[1000px]" : "max-h-0"
            }`}
          >
            <p
              data-aos="fade-left"
              data-aos-delay="200"
              className="text-base md:text-lg text-gray-700 leading-loose sm:leading-relaxed mt-6 sm:mt-8"
            >
              He is a Health and Safety Consultant and a Qualified Train Trainer
              in Health and Safety practices. A Registered Fire Safety Manager
              in UK; A Member, Chartered Institute of Environmental Health;
              Certified Member, Institute of Occupational Safety and Health and;
              A chartered Lead Auditor in ISO 45001 with Llyod Register, UK. A
              Member of the regulation of Engineering in Nigeria and a Health
              and Safety Professional in the United Kingdom. Olusegun has
              Consulted for both private and NHS Hospitals in the United
              Kingdom.
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="300"
              className="text-base md:text-lg text-gray-700 leading-loose sm:leading-relaxed mt-6 sm:mt-8"
            >
              He is a Health and Safety Consultant and a Qualified Train Trainer
              in Health and Safety practices. A Registered Fire Safety Manager
              in UK; A Member, Chartered Institute of Environmental Health;
              Certified Member, Institute of Occupational Safety and Health and;
              A chartered Lead Auditor in ISO 45001 with Llyod Register, UK. A
              Member of the regulation of Engineering in Nigeria and a Health
              and Safety Professional in the United Kingdom. Olusegun has
              Consulted for both private and NHS Hospitals in the United
              Kingdom. He is also the author of a bestselling book forwarded by
              Pastor E. A Adeboye, the General Overseer of RCCG - The Mystery of
              the Lord’s Prayer. Olusegun is happily married to his beautiful
              wife (Pastor Mrs Olufunke Stephen, who has also served for over 2
              decades as a Pastor in RCCG), and they are blessed with 3
              beautiful and Godly daughters (Dr Praise, Melody and Worship).
              With Jesus joy, join us to welcome Pastor Olusegun Stephen
            </p>
          </div>

          <button
            onClick={toggleShowMore}
            className="mt-4 text-indigo-700 font-bold hover:underline focus:outline-none"
          >
            {showMore ? "See Less" : "See More"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SeniorPastor;
