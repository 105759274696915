import React from "react";

const ParishPastors = () => {
  const pastors = [
    {
      name: "Pastor Mrs Olufunke Stephen",
      title: "Parish Pastor",
      img: require("./../image/Group7(1).png"),
    },
    {
      name: "Pastor Mrs Bimpe Lawal",
      title: "Deputy Parish Pastor",
      img: require("./../image/Group7(2).png"),
      delay: 400,
    },
    {
      name: "Pastor Victor Obanawu",
      title: "Assistant Parish Pastor",
      img: require("./../image/pastor_victor.png"),
      delay: 700,
    },
  ];

  return (
    <div className="w-full bg-white py-12">
      <div className="container mx-auto px-4">
        <h2
          data-aos="fade-down"
          className="text-xl sm:text-2xl md:text-5xl font-bold text-center text-indigo-700 mb-9 sm:mb-8"
        >
          Parish Pastors
        </h2>
        <div className="flex flex-col md:flex-row justify-center items-start space-y-12 md:space-y-0 md:space-x-8 lg:space-x-10">
          {pastors.map((pastor, index) => (
            <div
              key={index}
              className="flex flex-col items-center w-full md:w-1/3 max-w-sm"
            >
              <div className="w-full flex justify-center">
                <img
                  src={pastor.img}
                  alt={pastor.name}
                  data-aos="fade-up"
                  data-aos-delay={pastor.delay}
                  className="w-4/5 md:w-full mb-5 object-contain"
                />
              </div>
              <h2 className="text-m sm:text-l md:text-2xl font-bold text-black mb-2 text-left w-full">
                {pastor.name}
              </h2>
              <p className="text-base md:text-lg text-gray-700 text-left w-full">
                {pastor.title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ParishPastors;
