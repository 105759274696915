import React from "react";
import "../App.css";
import Slider from "react-slick";
import Container from "./Container";

const EventsComponent = () => {
  const events = [
    {
      title: "God Of Mercy",
      date: "23rd June 2024",
      image: require("../image/card3.png"),
    },
    {
      title: "Pursue Overtake & Recover",
      date: "30th June 2024",
      image: require("../image/card1.png"),
    },
    {
      title: "The Mandate",
      date: "19th May 2024",
      image: require("../image/card2.png"),
    },
    {
      title: "Anointing Service",
      date: "8th September, 2024",
      image: require("../image/card4.jpg"),
    },
    {
      title: "Effectual Door",
      date: "11th August, 2024",
      image: require("../image/card5.jpg"),
    },
    {
      title: "Father's Day 2024",
      date: "16th June, 2024",
      image: require("../image/card6.jpg"),
    },
    {
      title: "Let Everything That Has Breath Praise The Lord",
      date: "1st September, 2024",
      image: require("../image/card7.jpg"),
    },
    {
      title: "My Gates Are Open",
      date: "15th September 2024",
      image: require("../image/card8.jpg"),
    },
    {
      title: "My Own is Different",
      date: "5th March 2023",
      image: require("../image/card9.jpg"),
    },
    {
      title: "Prasie The Lord  4th August 2024",
      date: "4th August 2024",
      image: require("../image/card10.jpg"),
    },
    {
      title: "Pursue The Covenant of Prosperity",
      date: "25th August, 2024",
      image: require("../image/card11.jpg"),
    },
    {
      title: "Pursue Overtake & Recover...",
      date: "19th May 2024",
      image: require("../image/card3.png"),
    },
    {
      title: "Pursue Overtake & Recover...",
      date: "19th May 2024",
      image: require("../image/card1.png"),
    },
  ];

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
      <div className="mx-auto py-10">
        <Container>
          <h2
            // data-aos="fade-down"
            className="text-xl md:text-3xl font-semibold md:font-bold md:text-center mb-2"
          >
            UPCOMING EVENTS & ANNOUNCEMENTS
          </h2>
          <p
            className="text-md md:text-base md:text-center text-gray-600 mb-1 md:mb-8"
            // data-aos="fade-up"
          >
            Be In The Know About Our Current Programs And Events.
          </p>
        </Container>
        <Container>
          <div className="mx-auto w-full py-8">
            <Slider {...settings}>
              {events.map((event, index) => (
                <div key={index} className="mb-4 flex justify-center">
                  <div className="pl-2">
                    <img
                      src={event.image}
                      alt={event.title}
                      className="w-[280px] h-80 object-cover rounded-md mb-2"
                    />
                    <h3 className="font-semibold text-sm md:text-base">
                      {event.title}
                    </h3>
                    <p className="text-xs md:text-sm text-gray-600">
                      {event.date}
                    </p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </Container>
      </div>
    </>
  );
};

export default EventsComponent;
