import React from "react";
import backgroundImage from "./../image/goal.png";

const GoalSection = () => {
  // Define the animation for sliding in from the bottom

  return (
    <div
      className="relative flex items-center justify-center md:text-center h-[60vh] bg-cover bg-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="absolute inset-0 bg-[#191d50] opacity-70"></div>
      <div className="absolute inset-0 bg-black opacity-80"></div>

      {/* Sliding in the text from the bottom */}
      <div
        className="relative text-white max-w-2xl px-6 md:px-8 z-10 text-center"
        data-aos="zoom-out"
      >
        <h1
          data-aos="zoom-out"
          className="text-4xl md:text-5xl font-bold mb-4 leading-snug md:leading-tight"
        >
          Our Goal Is To
        </h1>
        <p
          data-aos="zoom-out"
          data-aos-delay="200"
          className="text-lg md:text-xl font-light mb-6 leading-relaxed md:leading-loose"
        >
          Make Heaven, Raise Generals In Faith, Take Many Along To Heaven, Be A
          Blessing To Our Generation
        </p>
      </div>
    </div>
  );
};

export default GoalSection;
