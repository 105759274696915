import React from "react";
import backgroundImage from "./../image/goal.png";
import Container from "./Container";
import ContactButton from "./ContactButton";
import { useNavigate } from "react-router-dom";

const SupportSection = () => {
  const navigate = useNavigate();
  const handleAboutUsClick = () => {
    navigate("/give");
  };
  return (
    <div
      className="relative flex items-center justify-center md:text-center h-[60vh] bg-cover bg-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="absolute inset-0 bg-[#2E3795] opacity-70"></div>
      <div className="absolute inset-0 bg-black opacity-80"></div>

      <Container>
        <div className="relative text-white z-10">
          <h1
            // data-aos="fade-down"
            className="text-2xl sm:text-5xl w-full font-bold mb-4 leading-tight"
          >
            To Support Our Mission and Ministries
          </h1>
          <p
            // data-aos="fade-up"
            className="text-lg sm:text-md md:text-sm font-light mb-6 leading-relaxed"
          >
            Whether you seek guidance, prayer, or simply wish to connect, our
            church is here for you. Schedule an appointment for counseling,
            prayer sessions, and more with ease.
          </p>
          <ContactButton
            stylex="px-20 py-5 text-md"
            label="Give"
            onClick={handleAboutUsClick}
          />
        </div>
      </Container>
    </div>
  );
};

export default SupportSection;
