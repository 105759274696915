import React from "react";
import backgroundImage from "./../image/Rectangle4.png";
import Container from "./Container";

const AboutHero = () => {
  return (
    <div
      className="relative flex items-center justify-center md:text-center mt-[5rem] md:mt-[6rem] h-[55vh] bg-cover bg-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="absolute inset-0 bg-[#2E3795] opacity-70"></div>
      <div className="absolute inset-0 bg-black opacity-70"></div>
      <Container>
        <div className="relative flex flex-col items-center text-white z-10">
          <h1
            // data-aos="zoom-in"
            className="text-4xl md:text-5xl font-bold mb-4"
          >
            WELCOME TO THE HOUSE OF MERCY <br className="hidden md:block" />{" "}
            HIGHWYCOMB
          </h1>
        </div>
      </Container>
    </div>
  );
};

export default AboutHero;
