import React from "react";
import ContactButton from "./ContactButton";
import heroImage from "./../image/redem.png";
import { useNavigate } from "react-router-dom";
import Container from "./Container";

const GudaianceSection = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/booking");
  };

  return (
    <Container>
      <div className="flex flex-col md:flex-row items-center mb-10 justify-center py-8 md:p-16 bg-white">
        {/* Image Section */}
        <div className="w-full md:w-3/5 h-auto" data-aos="fade-right">
          <img
            src={heroImage}
            alt="Pastor"
            className="rounded-lg h-auto object-cover"
          />
        </div>

        {/* Text Section */}
        <div className="mt-8 md:mt-0 md:ml-10 w-full text-left md:text-left">
          <h1
            data-aos="fade-left"
            className="text-2xl md:text-5xl font-bold text-gray-800 leading-tight"
          >
            Do you need Guidance?
          </h1>
          <p
            data-aos="fade-left"
            data-aos-delay="100"
            className="mt-4 text-lg md:text-xl text-gray-600"
          >
            Whether you seek guidance, prayer Pathner, or simply <br /> wish to
            connect, our church is here for you. Schedule <br /> an appointment
            for counselling, prayer sessions.
          </p>
          <div
            data-aos="fade-left"
            data-aos-delay="200"
            className="my-10 md:mt-8"
          >
            <ContactButton
              stylex="px-20 py-5 text-md"
              label="Connect"
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default GudaianceSection;
