import React from "react";
import { useNavigate } from "react-router-dom";
import ContactButton from "./ContactButton";
// import Container from "./Container";

const HeroSection = () => {
  const navigate = useNavigate();

  const handleAboutUsClick = () => {
    navigate("/about-us");
  };

  return (
    <div className="relative h-screen w-full overflow-hidden flex items-center justify-start">
      <img
        src={require("./../image/bg-1.png")}
        alt="Background"
        className="absolute inset-0 w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* Animated text container */}
      <div className="relative z-10 w-full mx-auto px-5 md:px-20 text-white">
        <h1 data-aos="fade-right" className="text-xl md:text-2xl md:mb-2">
          Welcome To Our Church
        </h1>

        <h1
          data-aos="fade-right"
          data-aos-delay="100"
          data-aos-mirror="true"
          className="text-5xl md:text-7xl font-bold mb-3 md:mb-6 leading-tight"
        >
          BECOME A PART OF
          <br className="hidden md:block" />
          OUR COMMUNITY
        </h1>
        <div data-aos="fade-right" data-aos-delay="200">
          <ContactButton
            stylex="px-20 py-5 text-md"
            label="About Us"
            onClick={handleAboutUsClick}
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
