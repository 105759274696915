import React from "react";
import Header from "../components/Header";
import AboutHero from "../components/AboutHero";
import Footer from "../components/Footer";
import MissionStatement from "../components/MissionStatement";
import Hopeless from "../components/Hopeless";
import SeniorPastor from "../components/SeniorPastor";
import ParishPastor from "../components/ParishPastor";
import Gallery from "../components/Gallary";
// import AnimatedSection from "../components/AnimatedSection";
import DivisionLeads from "../components/DivisionLeads";

// Define animation variants

export default function AboutUs() {
  return (
    <div className="overflow-hidden">
      <Header />
      <AboutHero />
      <MissionStatement />
      <Hopeless />
      <SeniorPastor />
      <ParishPastor />
      <DivisionLeads />
      <Gallery />
      <Footer />
    </div>
  );
}
