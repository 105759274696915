import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Image } from "@chakra-ui/react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Button,
  Grid,
} from "@chakra-ui/react";
import Container from "./Container";

const DivisionLeads = () => {
  const [expandedDivision, setExpandedDivision] = useState(null);

  const divisions = [
    {
        name: "Church Operations",
        leads: [
          { name: " Pastor Mrs. Bimpe Lawal", role: "Church Operations", image: require("../image/Group7(2).png") },
        ],
      },
      {
        name: "Facility Management",
        leads: [
          { name: "Ime Cletus", role: "Facility Mgt.",image: "ime.png" },
          ],
      },
     
      {
        name: "Admin & Account",
        leads: [
          { name: "Pastor Victor Obanawu", role: "Account", image: require("../image/pastor_victor.png") },
          
        ],
      },
      {
        name: "Training & Development",
        leads: [
          { name: "Babatunde Akanbi", role: "Training & Development", image: require("../image/Akanbi.jpg") },
          
        ],
      },
      {
        name: "Children Division",
        leads: [
          { name: "Oyenike Disu", role: "Children Division", image: require("../image/Disu.png") }, 
        ],
      },
      
      {
        name: "Digital & Media",
        leads: [
          { name: "Ebenzer Aladejobi", role: "Digital & Media", image: require("../image/Alade2.jpg") },
        ],
      },
  ];

  const toggleDivision = (index) => {
    setExpandedDivision(expandedDivision === index ? null : index);
  };

  return (
    <Container>
      <Box w="100vw" maxW="full" mx="auto" p={4}>
        <Heading as="h1" size="2xl" my={10} textAlign="center" color="blue.800">
          Division Leads
        </Heading>
        <VStack spacing={4}>
          {divisions.map((division, index) => (
            <Box key={index} w="100%" border="1px solid black">
              <Button
                onClick={() => toggleDivision(index)}
                w="100%"
                justifyContent="space-between"
                bg="white"
                _hover={{ bg: "gray.200" }}
                padding={6}
              >
                <Text color="black" fontWeight="bold" fontSize="lg">
                  {division.name}
                </Text>
                {expandedDivision === index ? (
                  <ChevronUp color="black" />
                ) : (
                  <ChevronDown color="black" />
                )}
              </Button>
              {expandedDivision === index && division.leads.length > 0 && (
                <Box p={4} bg="white" borderTop="1px solid black">
                  {division.leads.map((lead, leadIndex) => (
                    <Grid
                    key={leadIndex}
                    templateColumns="80px 1fr"
                    gap={16}
                    alignItems="center"
                    mb={3}
                  >
                    <Image
                      src={lead.image || "https://via.placeholder.com/60"}
                      alt={lead.name}
                      boxSize="80px"
                      borderRadius="full"
                    />
                    <VStack align="start" spacing={1}>
                      <Text color="black" fontSize="md" fontWeight="bold">
                        {lead.name === "TBC" ? "To Be Confirmed" : lead.name}
                      </Text>
                      <Text fontSize="sm" color="gray.600">
                        {lead.role === "TBC" ? "Role To Be Confirmed" : lead.role}
                      </Text>
                    </VStack>
                  </Grid>
                  ))}
                </Box>
              )}
            </Box>
          ))}
        </VStack>
      </Box>
    </Container>
  );
};

export default DivisionLeads;